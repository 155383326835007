define(["../templateFactoryModule", "angular"], function (templateFactoryModule) {
    "use strict";

    return templateFactoryModule.directive("templateFactoryLimitLength", [function () {
        return {
            restrict: "E",
            template: "<span translate ng-attr-title='{{::toTitle(value)}}'>{{::limitLength(value)}}</span>",
            scope: {
                value: "@"
            },
            controller: ["$scope", function ($scope) {
                $scope.toTitle = function (value) {
                    return isTooBig(value)
                        ? value
                        : "";
                };

                $scope.limitLength = function (value) {
                    return isTooBig(value)
                        ? value.substr(0, 297) + "..."
                        : value;
                };

                function isTooBig(value) {
                    return typeof value === "string" && value.length > 300;
                }


            }]
        };
    }]);
});