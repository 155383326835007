define(["./menuModule"], function (menuModule) {
    menuModule.controller("menuController", ["$scope", "$state", "authorizationService", function ($scope, $state, authorizationService) {
        authorizationService.getAuthorizedMenus().then(function (menus) {
            $scope.userMenus = menus;
        });

        $scope.toggleSideMenu = function () {
            $scope.menuClosed = !$scope.menuClosed;
        };

        $scope.toggleTopLevelMenu = function (menu) {
            var newState = !menu.opened;
            $scope.closeAllMenus();
            menu.opened = newState;
        };

        $scope.closeAllMenus = function closeAll() {
            $scope.userMenus.forEach(function (menuItem) {
                    menuItem.opened = false;
                }
            );
        };

        /**
         * Retorna a url a ser direcionada pelo item de menu, considerando estados vazios.
         * https://github.com/angular-ui/ui-router/issues/371
         */
        $scope.getHref = function (subMenu) {
            if (!subMenu.state) {
                return "";
            }
            return $state.href(subMenu.state, subMenu.stateParams || {});
        };
    }]);
});