define(["./timeModule", "angular", "text!./dateTimePicker.html", "lodash"], function dateTimePicker(timeModule, angular, template, _) {
    "use strict";

    return timeModule.directive("datetimepicker", ["timeService", "dateService", function (timeService, dateService) {
        return {
            template: template,
            scope: {
                "outerModel": "<ngModel",
                "ngDisabled": "=?"
            },
            require: "ngModel",
            link: function ($scope, $element, $attrs, ngModelController) {
                $scope.model = modelFormatter($scope.outerModel);

                function modelFormatter(modelValue) {
                    var timeOrNull = getTimeOrNull(modelValue);
                    var date = timeOrNull ? new Date(timeOrNull) : null;
                    return {
                        date: date,
                        time: date ? timeService.toMillis(date.getHours(), date.getMinutes()) : null
                    };
                }

                ngModelController.$formatters.push(modelFormatter);

                ngModelController.$validators.notParcialInput = function (modelValue, viewValue) {
                    return (_.isNil(viewValue.date)) === (_.isNil(viewValue.time));
                };

                ngModelController.$parsers.push(function (viewValue) {
                    var dateOrNull = getTimeOrNull(viewValue.date);
                    var timeOrNull = getTimeOrNull(viewValue.time);
                    if (_.isNil(dateOrNull) || _.isNil(timeOrNull)) {
                        return null;
                    }
                    var date = new Date(dateOrNull);
                    var time = timeService.toTime(timeOrNull);
                    var result = new Date(date.getFullYear(), date.getMonth(), date.getDate(), time.hours, time.minutes);
                    if (_.isNaN(result.getTime())) {
                        return null;
                    }
                    return result;
                });

                ngModelController.$validators.notEmptyWhenRequired = function (modelValue) {
                    var required = $attrs.required;
                    return !!(!required || (required && modelValue));
                };

                ngModelController.$render = function () {
                    $scope.model = angular.copy(ngModelController.$viewValue);
                };

                $scope.changeDate = function () {
                    if (modelChanged() && !_.isNil($scope.model.date) && _.isNil($scope.model.time)) {
                        $scope.model.time = 0;
                    }
                    $scope.change();
                };

                $scope.changeTime = function () {
                    if (modelChanged() && !_.isNil($scope.model.time) && _.isNil($scope.model.date)) {
                        $scope.model.date = new Date();
                    }
                    $scope.change();
                };

                $scope.change = function () {
                    if (modelChanged()) {
                        ngModelController.$setViewValue(angular.copy($scope.model));
                    }
                };

                $scope.currentTime = function () {
                    var newModel = modelFormatter(new Date());
                    $scope.model = newModel;
                    $scope.change();
                };

                ngModelController.$setViewValue(modelFormatter($scope.outerModel));
                ngModelController.$render();

                function modelChanged() {
                    return !angular.equals($scope.model, ngModelController.$viewValue);
                }

                $element.closest("form,ng-form").on("reset", function () {
                    ngModelController.$setViewValue({date: null, time: null});
                    ngModelController.$render();
                });

                function getTimeOrNull(date) {
                    return dateService.toMillis(date);
                }
            }
        };
    }]);
});