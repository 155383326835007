define([
    "./nlgScriptSqlQueryReportModule",
    "json!./paths.json",
    "../arrays/arrays",
    "xlsx",
    "lodash"
], function (module, paths, arrays, xlsx, _) {
    "use strict";

    return module.service("nlgScriptSqlServiceFactory", ["$http", "$q", "$parse", function ($http, $q, $parse) {
        return function nlgScriptSqlService(baseUrl) {
            var self = {
                getQueries: function () {
                    return $http.get(baseUrl + paths.services.report.baseUrl).then(getData);
                },
                getQuery: function (sourceId) {
                    //TODO: implementar no backend um método adequado pois findAll é muito ineficiente
                    return self.getQueries()
                        .then(function (data) {
                           return  _.find(data, {sourceId: sourceId});
                        });
                },
                executeQuery: function (queryId, queryData, firstResult, maxResults) {
                    return $http.post(baseUrl + paths.services.report.baseUrl + paths.services.report.query.execute + queryId, queryData, {
                        params: {
                            "firstResult": firstResult,
                            "maxResults": maxResults
                        }
                    });
                },
                getProjectionFormatter: getProjectionFormatter,
                getPipeFormatter: getPipeFormatter,
                getArrayOfTranslatedHeaders: getArrayOfTranslatedHeaders,
                getArrayOfRowValues: getArrayOfRowValues,
                exportSpreadsheet: function (queryId, queryData, fileType) {
                    return $http.post(baseUrl + paths.services.report.baseUrl + paths.services.report.query.spreadsheet + queryId, queryData)
                        .then(function (req) {
                            var data = getData(req);
                            var fileName = getFileNameFromHeaders(req.headers);
                            var sheetName = getSheetNameFromFileName(fileName);
                            var translatedHeaders = getArrayOfTranslatedHeaders(data.headers);
                            var workbook = xlsx.utils.book_new();
                            if (fileType === "xls" || fileType === "xlsx") {
                                var splittedSheets = arrays.split(data.tuples, 65535);
                                for (var index = 0; index < splittedSheets.length; index++) {
                                    generateSheetAndAppendToWorkbook(splittedSheets[index], translatedHeaders, data.projections, sheetName + index, workbook);
                                }
                            } else {
                                generateSheetAndAppendToWorkbook(data.tuples, translatedHeaders, data.projections, sheetName, workbook);
                            }
                            var writingOptions = {
                                type: "buffer",
                                bookType: fileType === "xls" ? "biff8" : fileType,
                                compression: fileType === "xlsx"
                            };
                            var defer = $q.defer();
                            setTimeout(function () {
                                // Executa fora do ciclo de digest
                                xlsx.writeFile(workbook, fileName + "." + fileType, writingOptions);
                                defer.resolve();
                            });
                            return defer.promise;
                        });
                }
            };

            return self;
        };

        function format(value, formatter) {
            return $parse("value" + formatter)({value: value});
        }

        function getFileNameFromHeaders(headers) {
            var contentDispositionHeader = headers("Content-Disposition");
            var result = contentDispositionHeader.split(";")[1].trim().split("=")[1];
            return result.replace(/"/g, "");
        }

        function getSheetNameFromFileName(fileName) {
            if (fileName.length < 29) {
                return fileName;
            }
            return fileName.substring(0, 28);
        }

        function generateSheetAndAppendToWorkbook(tuples, headers, projections, sheetName, workbook) {
            var sheet = xlsx.utils.aoa_to_sheet([headers]);
            xlsx.utils.sheet_add_aoa(sheet, getArrayOfFormattedValues(tuples, headers, projections), {origin: "A2"});
            xlsx.utils.book_append_sheet(workbook, sheet, sheetName);
        }

        function getArrayOfFormattedValues(tuples, headers, projections) {
            var rowValues = getArrayOfRowValues(tuples);
            arrays.each(headers, function (header, index) {
                var formatter = "";
                arrays.each(projections, function (projection) {
                    if (projection.name === header) {
                        var rawFormatter = getProjectionFormatter(projection.formatter);
                        formatter = getPipeFormatter(rawFormatter);
                    }
                });
                if (formatter !== "") {
                    for (var i = 0; i < rowValues.length; i++) {
                        rowValues[i][index] = format(rowValues[i][index], formatter);
                    }
                }
            });
            return rowValues;
        }

        function getArrayOfTranslatedHeaders(headers) {
            var translatedHeaders = [];
            arrays.each(headers, function (header) {
                var formatter = " | translate";
                translatedHeaders.push(format(header, formatter));
            });
            return translatedHeaders;
        }

        function getArrayOfRowValues(tuples) {
            var results = [];
            if (!tuples) {
                return results;
            }
            arrays.each(tuples, function (tuple) {
                if (tuple) {
                    results.push(tuple.rowValues);
                }
            });
            return results;
        }

        function getProjectionFormatter(rawFormatter) {
            var initialIndex = rawFormatter.lastIndexOf(".") + 1;
            return rawFormatter.substring(initialIndex, rawFormatter.length);
        }

        function getPipeFormatter(formatter) {
            switch (formatter) {
                case "DATETIME":
                    return " | date : 'short'";
                case "DATE":
                    return " | date : 'dd/MM/yyyy'";
                case "TIME":
                    return " | date : 'HH:mm'";
                case "THREE_DECIMALS":
                    return " | number : 3";
                case "TWO_DECIMALS":
                    return " | number : 2";
                case "TRANSLATE":
                    return " | translate";
                case "GROUP_TRANSLATE":
                    return " | groupTranslate";
                case "DURATION":
                    return " | duration";
                default:
                    return "";
            }
        }
    }]);

    function getData(response) {
        return response.data;
    }
});