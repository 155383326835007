define(["./templateFactoryModule", "angular"], function (templateFactoryModule, angular) {
    "use strict";

    /**
     * @ngdoc object
     * @name templateFactory.class:TemplateFactoryConfiguration
     * @description
     * Objeto que discrimina qual será a fábrica utilizada para criação do template e que contém
     * a configuração necessária para seu preenchimento.
     * */

    /**
     * @ngdoc object
     * @name templateFactory.class:TemplateFactoryConfiguration.type
     * @propertyOf templateFactory.class:TemplateFactoryConfiguration
     * @description
     * Propriedade que define qual template será utilizado para renderização da célula.
     * Este campo é opcional.
     * Quando não fornecido, será utilizado o template padrão.
     * Quando a propriedade `template` é definida, esta propriedade é ignorada.
     * */

    /**
     * @ngdoc object
     * @name templateFactory.class:TemplateFactoryConfiguration.template
     * @propertyOf templateFactory.class:TemplateFactoryConfiguration
     * @description
     * String opcional que contém o template que deverá ser utilizado.
     * Quando esta propriedade é definida, a propriedade `type` é ignorada.
     * */

    /**
     * @ngdoc function
     * @name templateFactory.templateFactory
     * @description
     * Serviço para criação e preenchimento de templates.
     *
     * Um template é um objeto jquery que representa uma estrutura DOM.
     * Assim, esta factory é responsável por criar um template e preenchê-lo, dado as configurações de entrada
     * fornecidas.
     *
     * @param {TemplateFactoryConfiguration|string} config
     * Configurações para escolher e preencher o template.
     * Quando é uma string, será convertido para um
     * {@link templateFactoryDefaultTemplate.class:TemplateFactoryConfiguration TemplateFactoryConfiguration}
     * cujo campo `name` será preenchido com o valor da string.
     *
     * @returns {jquery} Objeto jquery com o template preenchido.
     * */
    return templateFactoryModule.service("templateFactory", ["templateFactoryMapper", function (templateFactoryMapper) {
        return function (config) {
            var result = angular.element("<div>");

            if (!config) {
                return result;
            }

            if (angular.isString(config)) {
                config = {
                    "name": config
                };
            }

            var templateElement = config.template;
            if (!templateElement) {
                var templateFactory = templateFactoryMapper[config.type || "default"];
                if (!templateFactory) {
                    throw new Error("no renderer for type " + config.type);
                }
                templateElement = templateFactory(config);
            }
            templateElement = angular.element(templateElement);

            addAttr("ng-disabled", config.disabled);
            addAttr("ng-class", config.ngClass);
            addAttr("ng-style", config.ngStyle);
            addAttr("ng-attr-title", config.title);
            addAttr("ng-change", config.change );

            return result.append(templateElement);

            function addAttr(attrName, attrValue) {
                if (attrValue) {
                    templateElement.attr(attrName, attrValue);
                }
            }
        };
    }]);
});