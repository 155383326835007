define(["./nlgBasketModule", "text!./nlgBasket.html"], function (nlgBasketModule, nlgBasketTemplate) {
    "use strict";

    return nlgBasketModule.directive("nlgBasket", [function () {
        return {
            restrict: "E",
            template: nlgBasketTemplate,
            scope: {
                service: "@service",
                domain: "@?",
                selection: "=?",
                registerBasketApi: "&?",
                drop: "=?",
                allowMoveTo: "&?",
                allowEditTrip: "@?",
                afterPaginate: "=?",
                filterPredicate: "=?",
                extraFilterActions: "=?",
                basketService: "<?",
                filterMode: "@?" // "template" ou "patch"
            },
            controller: ["$scope", "nlgBasketService", "loadingService", "$q", "$attrs", function ($scope, nlgBasketService, loadingService, $q, $attrs) {
                var paginationApi,
                    basketFilterPatch = $scope.filterMode === "template" ? null : [],
                    basketApi = {},
                    filtered = false,
                    paginationApiDefer = $q.defer(),
                    serviceDefinitionDefer = $q.defer(),
                    filterService = $scope.basketService || nlgBasketService;

                var doPresentationTree = function (firstResult, maxResults) {
                    var params = {
                        firstResult: firstResult,
                        maxResults: maxResults
                    };
                    return loadingService(filterService.doFilterPresentationTree(
                        $scope.service,
                        basketFilterPatch,
                        params));
                };

                $scope.canMoveToNode = function (node) {
                    return $attrs.allowMoveTo ? $scope.allowMoveTo({node: node}) : true;
                };

                $scope.paginationOptions = {
                    pagination: {
                        maxPageSize: 10,
                        onPaginate: function (firstResult, maxResults) {
                            var filterPredicate = $scope.filterPredicate || $q.resolve;
                            return filterPredicate().then(function(){
                                $scope.root = {};
                                return doPresentationTree(firstResult, maxResults)
                                    .then(function (result) {
                                        var response = result.data;
                                        $scope.root = response.result[0];
                                        return response;
                                    }).then(function(root){
                                        if($scope.afterPaginate){
                                            $scope.afterPaginate($scope.root);
                                        }
                                        return root;
                                    });
                            });
                        }
                    },
                    onRegisterPagination: function (api) {
                        paginationApi = api;
                        paginationApiDefer.resolve();
                    }
                };

                $scope.root = {};

                $scope.filter = function (patch, template) {
                    filtered = true;
                    basketFilterPatch = $scope.filterMode === "template" ? template : patch;
                    return paginationApi.seekPage(1);
                };

                $scope.registerTreeApi = function (api) {
                    basketApi.toggleSelection = api.toggleSelection;
                    basketApi.changePresentationIconColor = api.changePresentationIconColor;
                };

                basketApi.setData = function (data) {
                    $scope.root = data;
                };

                basketApi.getData = function () {
                    return $scope.root;
                };

                basketApi.clearResults = function(){
                    paginationApi.clearResults();
                    $scope.root = {};
                    return filterService.getEmptyPresentationTree($scope.service)
                        .then(function (emptyRoot) {
                            basketApi.setData(emptyRoot);
                        });
                };

                basketApi.refreshFilter = function () {
                    if (filtered) {
                        return paginationApi.seekPage(1);
                    }
                    return $q.resolve();
                };

                basketApi.getSelection = function () {
                    return $scope.selection;
                };

                basketApi.getLastAppliedFilterPatches = function () {
                    return basketFilterPatch;
                };

                $scope.context = {
                    refilter: basketApi.refreshFilter,
                    getSelection: basketApi.getSelection
                };

                $scope.$watch(function () {
                    return $scope.service;
                }, function (service) {
                    if (service !== "") {
                        basketApi.refreshFilter();
                        serviceDefinitionDefer.resolve();
                    }
                });

                $q.all([
                    paginationApiDefer.promise,
                    serviceDefinitionDefer.promise
                ]).then(basketApi.clearResults)
                .finally(function () {
                    if ($scope.registerBasketApi) {
                        $scope.registerBasketApi({api: basketApi});
                    }
                });
            }]
        };
    }]);
});