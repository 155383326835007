define([
    "angular",
    "./nlgLGPDModule",
    "lodash"
], function (angular, lgpdModule, _) {
    "use strict";

    return lgpdModule.controller("nlgLGPDEditionController", [
        "$scope",
        "autocompleteFactory",
        "messagesModal",
        "remoteExceptionHandler",
        "$state",
        "$q",
        "originalEntity",
        "nlgLGPDService",
        "loadingService",
        "$translate",
        function ($scope, autocompleteFactory, messagesModal, remoteExceptionHandler, $state, $q, originalEntity, nlgLGPDService, loadingService, $translate) {
            $scope.model = {
                owner: null,
                entity: null,
                originalEntity: null,
                personal: null,
                sensitive: null,
                anonymizable: null,
                justification: null
            };

            $scope.serviceName = "nlgLGPDService";
            $scope.accessReference = "lgpd.logLgpdEntity";

            if (originalEntity) {
                $scope.model.owner = {
                    entityName: angular.copy(originalEntity.entityName),
                    presentationName: angular.copy(originalEntity.presentationName),
                    artifactName: originalEntity.artifactName
                };

                $scope.model.personal = angular.copy(originalEntity.personal);
                $scope.model.sensitive = angular.copy(originalEntity.sensitive);
                $scope.model.anonymizable = angular.copy(originalEntity.anonymizable);
                $scope.model.justification = angular.copy(originalEntity.justification);

                if (originalEntity.id) {
                    $scope.model.originalEntity = angular.copy(originalEntity);
                    $scope.model.entity = angular.copy(originalEntity);
                }
            }

            $scope.getAllEntityOwners = function () {
                return loadingService($q.all(nlgLGPDService.getAllEntityOwners()))
                    .then(function (allItems) {
                        var resultNames = [];
                        allItems.forEach(function (entry) {
                            entry.value.forEach(function (name) {
                                var nameEntry = {
                                    entityName: name.entityName,
                                    presentationName: name.presentationName,
                                    tableName: name.tableName,
                                    artifactName: entry.key.artifactName,
                                };
                                resultNames.push(nameEntry);
                            });
                        });
                        $scope.entityOwners = resultNames;
                        return resultNames;
                    });
            };

            $scope.entityOwners = [];
            (function () {
                $scope.getAllEntityOwners();
            }());

            $scope.getEntitiesByOwner = function (owner) {
                return nlgLGPDService.getEntitiesByOwner(owner)
                    .then(function (response) {
                        return response.data;
                    });
            };

            $scope.getEntityName = function (tag) {
                if (!tag) return "";
                return "(" + _.capitalize($translate.instant(tag.artifactName)) + ") - " + tag.presentationName;
            };

            $scope.getEntityTableName = function (tag) {
                return tag.tableName;
            };

            $scope.getEntityFieldName = function (tag) {
                if (!tag) return "";
                if (!tag.presentationField) return tag.entityField;
                return tag.presentationField;
            };

            $scope.changeEntityOwner = function () {
                if (!$scope.model.owner) {
                    $scope.model.entity = null;
                }
            };

            $scope.save = function () {
                if ($scope.model.entity !== $scope.model.originalEntity) {

                    $scope.model.entity.personal = angular.copy($scope.model.personal);
                    $scope.model.entity.sensitive = angular.copy($scope.model.sensitive);
                    $scope.model.entity.anonymizable = angular.copy($scope.model.anonymizable);
                    $scope.model.entity.justification = angular.copy($scope.model.justification);

                    $scope.model.entity.artifactName = $scope.model.owner.artifactName;

                    var artifactName = $scope.model.entity.artifactName;
                    return nlgLGPDService.save($scope.model.entity)
                        .then(function (result) {
                            $scope.model.entity = result.data;
                            $scope.model.originalEntity = result.data;
                            $scope.model.entity.artifactName = $scope.model.owner.artifactName;
                            messagesModal("dialog.success", [{
                                keyBundle: "lgpd.edition.save.success",
                                parameters: []
                            }]).then(function () {
                                $state.go("lgpd.editLgpdEntity", {
                                    reload: true,
                                    lgpdEntityId: $scope.model.entity.id,
                                    artifactName: artifactName
                                });
                            });
                        }).catch(remoteExceptionHandler());
                }
            };

            $scope.saveAndContinue = function () {
                if ($scope.model.entity !== $scope.model.originalEntity) {

                    $scope.model.entity.personal = angular.copy($scope.model.personal);
                    $scope.model.entity.sensitive = angular.copy($scope.model.sensitive);
                    $scope.model.entity.anonymizable = angular.copy($scope.model.anonymizable);
                    $scope.model.entity.justification = angular.copy($scope.model.justification);

                    $scope.model.entity.artifactName = $scope.model.owner.artifactName;

                    return nlgLGPDService.save($scope.model.entity)
                        .then(function (result) {
                            $scope.model.entity = result.data;
                            $scope.model.originalEntity = result.data;
                            messagesModal("dialog.success", [{
                                keyBundle: "lgpd.edition.save.success",
                                parameters: []
                            }]).then(function () {
                                $state.go($state.current, {
                                    reload: Date.now()
                                });
                            });
                        }).catch(remoteExceptionHandler());
                }
            };

            $scope.back = function () {
                return $state.go("lgpd.lgpdEntity");
            };

            $scope.canSave = function () {
                if ($state.params.version) {
                    return false;
                }
                return $scope.model.owner
                    && $scope.model.entity
                    && $scope.model.justification
                    && $scope.model.personal;
            };
        }]);
});
