define([
    "./dashboardModule",
    "lodash"
], function (dashboardModule, _) {
    "use strict";

    return dashboardModule.service("dashboardExport", ["$translate", "spreadsheetServiceInvoker", function ($translate, spreadsheetServiceInvoker) {

        var exportBundle = "dashboard.export.";

        var styleExportDashboard = {
            headers: true,
            column: {
                style: {
                    Font: {
                        Bold: "1",
                        Color: "#FFFFFF"

                    },
                    Alignment: {
                        Horizontal: "Center"
                    },
                    Interior: {
                        Color: "#1CBBB2",
                        Pattern: "Solid"
                    }
                }
            }
        };

        var convertedJavaTypes = function (element) {
            var convertedElement = {};
            if (element.value === null) {
                convertedElement = "";
            } else if (element.javaType === "org.joda.time.DateTime") {
                var dateFormater = new Date(element.value);
                convertedElement = dateFormater.toLocaleString();
            } else if (element.javaType === "java.lang.Double") {
                convertedElement = parseFloat(element.value).toFixed(2);
            } else if (element.javaType === "java.lang.Boolean") {
                convertedElement = $translate.instant(exportBundle + element.alias + "." + element.value);
            } else if (element.javaType === "java.lang.String") {
                convertedElement = $translate.instant(exportBundle + element.value);
            } else {
                convertedElement = exportTranslate(element.value);
            }
            return convertedElement;
        };

        var exportTranslate = function (propertyName) {
            var convertedElement = $translate.instant(exportBundle + propertyName);
            if (convertedElement === (exportBundle + propertyName)) {
                convertedElement = propertyName;
            }
            return convertedElement;
        };

        return function (results, sheetName) {
            var exportableData = [];
            results.forEach(function (chartExportResult) {
                _.map(chartExportResult, function (eachResult) {
                    var map = {};
                    eachResult.forEach(function (element) {
                        var headerName = exportTranslate(element.alias);
                        map[headerName] = convertedJavaTypes(element);
                    });
                    exportableData.push(map);
                });
            });
            spreadsheetServiceInvoker.exportData(sheetName, exportableData, styleExportDashboard);
        };
    }]);
});
